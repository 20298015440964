import type { Schemas } from "#shopware";
import { useCart as _coreFunctionality } from "@shopware/composables";

const _useCart = () => {
	const coreFunctionality = _coreFunctionality();
	const { apiClient } = useShopwareContext();
	const { open } = useShoppingCartView();
	const { selectedShippingMethod } = useSessionContext();
	const _storeCart = useContext<Schemas["Cart"]>("swCart");

	const cart = coreFunctionality.cart as unknown as ComputedRef<Schemas["Cart"]>;
	const localPickupLocation = useLocalStorage("pickuplocation", "");

	const errors = computed(() => cart.value?.errors || {});

	const hasCrititalErrors = computed(() => Object.values(errors.value).some((error) => error.level === 20));

	const retrievePickUpLocationFromLocalStorage = () => {
		if(import.meta.client && localPickupLocation.value){
			return JSON.parse(localPickupLocation.value) as Schemas["KwbPickupLocation"]
		}
		else{
			return undefined
		}
	}

	const selectedPickupLocation = computed<Schemas["KwbPickupLocation"] | undefined>(
		() => cart.value?.extensions?.pickupLocation ? cart.value?.extensions?.pickupLocation as Schemas["KwbPickupLocation"] : retrievePickUpLocationFromLocalStorage()
	);

	const timeslot = computed(() => cart.value?.extensions?.deliveryTimeSlot);

	// @TODO: Check need for watcher
	watch(
		[selectedShippingMethod, selectedPickupLocation],
		async ([newMethod, newLocation], [oldMethod, oldLocation]) => {
			if (
				(!!oldMethod && newMethod?.id !== oldMethod.id) ||
				(!!oldLocation && newLocation?.id !== oldLocation.id)
			) {
				updateExtension({
					deliveryTimeSlot: null,
				});
			}
		}
	);

	const updateExtension = async (extension: any) => {

		if(extension.hasOwnProperty('pickupLocationId')){
			await storePickUpLocationInLocalStorage(extension);
		}
		// @ts-expect-error
		await apiClient.invoke("updateCartExtension post /checkout/cart", { body: extension });
		coreFunctionality.refreshCart();
	};

	const storePickUpLocationInLocalStorage = async (location: any) => {
		const { data: pickUpLocationData } = await apiClient.invoke('getPickupLocationDetails get /pickup-location/' + location.pickupLocationId);
		if(pickUpLocationData){
			localPickupLocation.value = JSON.stringify(pickUpLocationData)
		}
	}

	const addProduct = async (params: { id: string; quantity?: number }) => {
		await coreFunctionality.addProduct(params);

		if(localPickupLocation.value && !cart.value.extensions?.pickupLocation){
			updateExtension({
				pickupLocationId: selectedPickupLocation.value.id
			})
		}

		if (Object.keys(errors.value).length) {
			open();
		}
	};

	const emptyCartMergeState = async () => {
		const { data } = await apiClient.invoke("emptyCartMergeState post /checkout/cart/empty-cart-merge-state");
		_storeCart.value = data;
	};

	return {
		...coreFunctionality,
		addProduct,
		cart,
		hasCrititalErrors,
		selectedPickupLocation,
		timeslot,
		updateExtension,
		emptyCartMergeState
	};
};

export const useCart = createSharedComposable(_useCart);
