import { useCheckout as _coreFunctionality } from "@shopware/composables";
import type { ShippingMethodTechnicalName } from "~/types/kippie";
import type { Schemas } from "#shopware";


const SHIPPING_METHODS: Record<
	ShippingMethodTechnicalName,
	{ id: string; name: string; technicalName: ShippingMethodTechnicalName }
> = {
	pickup: {
		id: "018a9309f14b700297a1002cd8616773",
		name: "Afhalen",
		technicalName: "pickup"
	},
	delivery: {
		id: "018a9309f14b700297a1002cd96162fe",
		name: "Bezorgen",
		technicalName: "delivery"
	}
};

const _useCheckout = () =>  {
    const coreFunctionality = _coreFunctionality();
    const shippingMethodsList = Object.values(SHIPPING_METHODS) as Partial<Schemas["ShippingMethod"]>[];

    return {
        ...coreFunctionality,
        shippingMethodsList, 
		SHIPPING_METHODS
    };
}

export const useCheckout = createSharedComposable(_useCheckout);