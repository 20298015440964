import { useSessionContext as _coreFunctionality } from "@shopware/composables";
import { useCheckout } from "./useCheckout";

import type { Schemas } from "#shopware";

const _useSessionContext = (newContext?: Schemas["SalesChannelContext"]) => {
	const coreFunctionality = _coreFunctionality(newContext);
	const { sessionContext } = coreFunctionality;

	const selectedShippingMethod = computed(() => {
		if (!sessionContext.value) return;

		const shippingMethod = useCheckout().shippingMethodsList.find(
			(method) => method.id === sessionContext.value!.shippingMethod?.id
		);
		if (!shippingMethod) return;

		return {
			...sessionContext.value.shippingMethod,
			...shippingMethod
		};
	});

	return {
		...coreFunctionality,
		selectedShippingMethod
	};
};

export const useSessionContext = createSharedComposable(_useSessionContext);
