<script setup lang="ts">
import { SpeedInsights } from "@vercel/speed-insights/nuxt";
import { Analytics } from "@vercel/analytics/nuxt";

import { DEPOSIT_PRODUCT_IDS } from "~/lib/constants";
import type { Schemas } from "#shopware";

const { apiClient } = useShopwareContext();

const { data: sessionContextData } = await useAsyncData("sessionContext", async () => {
	const contextResponse = await apiClient.invoke("readContext get /context");
	return contextResponse.data;
});

if (sessionContextData.value) {
	useSessionContext(sessionContextData.value);
}

const { refreshCart } = useCart();
const { search } = useProductSearch();

// hotfix create something durable with default language because of bug in samsung internet browser
const { getAvailableLanguages } = useInternationalization();
const { setLanguage } = useSessionContext();

const { data: languages } = await useAsyncData("languages", async () => {
	return await getAvailableLanguages();
});

const cookie = useCookie("sw-language-id");
cookie.value = languages.value?.elements?.[0]?.id as string;
await setLanguage(languages.value?.elements?.[0] as Schemas["Language"]);

onMounted(async () => {
	refreshCart();

	DEPOSIT_PRODUCT_IDS.forEach((productId) => {
		preloadAsyncData(`shopware:product:${productId}`, () =>
			search(productId, {
				criteria: {
					includes: {
						product_cross_selling: ["id", "name", "translated.name"]
					},
					associations: {
						crossSellings: {
							limit: 1,
							filter: [
								{
									type: "equals",
									field: "active",
									value: true
								}
							]
						},
						media: {},
						options: {},
						properties: {
							associations: {
								group: {}
							}
						},
						allergens: {}
					}
				}
			})
		);
	});
});

useDeliveryParameters();
</script>

<template>
	<div>
		<NuxtRouteAnnouncer />
		<SpeedInsights />
		<ClientOnly>
			<Analytics />
		</ClientOnly>
		<NuxtLayout>
			<NuxtLoadingIndicator color="repeating-linear-gradient(to right,#FFD932 0%,#FFE781 50%,#FFD932 100%)" />
			<NotifyProvider />
			<NuxtPage />
			<DrawerProvider />
			<LazyFullScreenSearch />
			<LazyShoppingCartSidebar />
		</NuxtLayout>
	</div>
</template>
