import revive_payload_client_rRB6FnZuGq from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vC8Y4URYPK from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_assdbCLE8W from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RA8QUXTAar from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.1.0_@types+node@20.17.17_jiti@2.4.2_terser@5.38_bno2jjg52qruq6ksy44juoapui/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_pBpUAuF6C6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_y3ZGRC1asY from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_07RZEDSyeR from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_IWP89Bas6k from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/apps/storefront/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/storefront/.nuxt/components.plugin.mjs";
import prefetch_client_9csywGm2vh from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _001_notivue_client_bE2FHKqHiu from "/vercel/path0/apps/storefront/.nuxt/001.notivue.client.mjs";
import plugin_Ge5BB5kGZx from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@8.3.1_@bugsnag+core@8.2.0_magicast@0.3.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_GbDgdad95D from "/vercel/path0/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.12_typescript@5.6.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_62RrYlRZYS from "/vercel/path0/node_modules/.pnpm/@shopware+nuxt-module@1.3.1_magicast@0.3.5_typescript@5.6.3/node_modules/@shopware/nuxt-module/plugin.ts";
export default [
  revive_payload_client_rRB6FnZuGq,
  unhead_vC8Y4URYPK,
  router_assdbCLE8W,
  _0_siteConfig_RA8QUXTAar,
  payload_client_pBpUAuF6C6,
  navigation_repaint_client_y3ZGRC1asY,
  check_outdated_build_client_07RZEDSyeR,
  chunk_reload_client_IWP89Bas6k,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9csywGm2vh,
  _001_notivue_client_bE2FHKqHiu,
  plugin_Ge5BB5kGZx,
  plugin_GbDgdad95D,
  plugin_62RrYlRZYS
]